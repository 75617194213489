import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import useAuthStore from "globals/authStore";
import { parseJwt } from "globals/publicData";
//import CpBlog from "containers/BusinessDashboard/CpBlog";
//import BlogPageNew from "containers/BlogPage/BlogPageNew";
//import BlogPageForTag from "containers/BlogPage/BlogPageForTag";
//import BlogPageForAllPosts from "containers/BlogPage/BlogPageForAllPosts";
//import ListingBookingOne from "containers/ListingDetailPage/ListingBookingOne";
//import ListingBookingTwo from "containers/ListingDetailPage/ListingBookingTwo";
//import ListingBookingThree from "containers/ListingDetailPage/ListingBookingThree";
// import ApHome from "containers/AdminPanel/ApHome";
// import ApLogin from "containers/AdminPanel/ApLogin";
//import CpRequests from "containers/BusinessDashboard/CpRequests";
import { Ring } from "react-awesome-spinners";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../globals";
import useCPStore from "globals/controlPanelStore";
import useAppStore from "globals/appStore";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";

import FooterNav from "components/FooterNav";
import Header3 from "components/Header/Header3";
import DelayedFallback from "components/DelayedFallback";

const CpChangePassword = lazy(() => import("containers/BusinessDashboard/CpChangePassword"));
const CpLinks = lazy(() => import("containers/BusinessDashboard/CpLinks"));
const PageEntity = lazy(() => import("containers/Subdomain Entities/PageEntity"));
const PageRestaurant = lazy(() => import("containers/Subdomain Entities/PageRestaurant"));
const SkasRestaurant = lazy(() => import("containers/Subdomain Entities/SkasRestaurant"));
const TrackRequests = lazy(() => import("containers/BookingPages/TrackRequests"));
const RequestOffers = lazy(() => import("containers/BookingPages/RequestOffers"));
const CpQualifiedLeads = lazy(() => import("containers/BusinessDashboard/CpQualifiedLeads"));
const CpQualifiedLeadsOwners = lazy(() => import("containers/BusinessDashboard/CpQualifiedLeadsOwners"));
const RequestMatchedListings = lazy(() => import("containers/BookingPages/RequestMatchedListings"));
const RequestPinnedListings = lazy(() => import("containers/BookingPages/RequestPinnedListings"));
const CpCreateOffer = lazy(() => import("containers/BusinessDashboard/CpCreateOffer"));
const SubPage2 = lazy(() => import("containers/Subdomain/SubPage2"));
const CpForgotPassword = lazy(() => import("containers/BusinessDashboard/CpForgotPassword"));
const CpReports = lazy(() => import("containers/BusinessDashboard/CpReports"));
const PageCilio = lazy(() => import("containers/Subdomain/PageCilio"));
const CpAssessmentHome = lazy(() => import("containers/BusinessDashboard/CpAssessmentHome"));
const CpAssessmentProspects = lazy(() => import("containers/BusinessDashboard/CpAssessmentProspects"));
const CpAssessmentProspectsTwo = lazy(() => import("containers/BusinessDashboard/CpAssessmentProspectsTwo"));
const CpSpaceProspectsAdmins = lazy(() => import("containers/BusinessDashboard/CpSpaceProspectsAdmins"));
const CpB2b2cProspectsAdmins = lazy(() => import("containers/BusinessDashboard/CpB2b2cProspectsAdmins"));
const CpLinkTracker = lazy(() => import("containers/BusinessDashboard/CpLinkTracker"));
const CpQLsQC = lazy(() => import("containers/BusinessDashboard/CpQLsQC"));
const PageHome = lazy(() => import('../containers/PageHome/PageHome'));
const Page404 = lazy(() => import('../containers/Page404/Page404'));
const ListingStayPage = lazy(() => import('../containers/ListingStayPage/ListingStayPage'));
const ListingStayMapPage = lazy(() => import('../containers/ListingStayPage/ListingStayMapPage'));
const GounaMap = lazy(() => import('../containers/ListingStayPage/GounaMap'));
const ListingStayDetailPage = lazy(() => import('../containers/ListingDetailPage/ListingStayDetailPage'));
const ListingExperiencesDetailPage = lazy(() => import('../containers/ListingDetailPage/ListingExperiencesDetailPage'));
const ListingCarDetailPage = lazy(() => import('../containers/ListingDetailPage/ListingCarDetailPage'));
const CheckOutPage = lazy(() => import('../containers/CheckOutPage/CheckOutPage'));
const PayPage = lazy(() => import('../containers/PayPage/PayPage'));
const AuthorPage = lazy(() => import('../containers/AuthorPage/AuthorPage'));
const AccountPage = lazy(() => import('../containers/AccountPage/AccountPage'));
const AccountPass = lazy(() => import('../containers/AccountPage/AccountPass'));
const PinnedSpaces = lazy(() => import('../containers/AccountPage/PinnedSpaces'));
const AccountBilling = lazy(() => import('../containers/AccountPage/AccountBilling'));
const PageContact = lazy(() => import('../containers/PageContact/PageContact'));
const PageAbout = lazy(() => import('../containers/PageAbout/PageAbout'));
const PageReviews = lazy(() => import('../containers/PageReviews/PageReviews'));
const PagePartners= lazy(() => import('../containers/PagePartners/PagePartners'));
const PageSignUp = lazy(() => import('../containers/PageSignUp/PageSignUp'));
const PageLogin = lazy(() => import('../containers/PageLogin/PageLogin'));
const PageSubcription = lazy(() => import('../containers/PageSubcription/PageSubcription'));
const BlogPage = lazy(() => import('../containers/BlogPage/BlogPage'));
const BlogSingle = lazy(() => import('../containers/BlogPage/BlogSingle'));
const PageAddListing0= lazy(() => import('../containers/PageAddListing1/PageAddListing0'));
const PageAddListing1= lazy(() => import('../containers/PageAddListing1/PageAddListing1'));
const PageAddListing2= lazy(() => import('../containers/PageAddListing1/PageAddListing2'));
const PageAddListing3= lazy(() => import('../containers/PageAddListing1/PageAddListing3'));
const PageAddListing4= lazy(() => import('../containers/PageAddListing1/PageAddListing4'));
const PageAddListing5= lazy(() => import('../containers/PageAddListing1/PageAddListing5'));
const PageAddListing6= lazy(() => import('../containers/PageAddListing1/PageAddListing6'));
const PageAddListing7= lazy(() => import('../containers/PageAddListing1/PageAddListing7'));
const PageAddListing8= lazy(() => import('../containers/PageAddListing1/PageAddListing8'));
const PageAddListing9= lazy(() => import('../containers/PageAddListing1/PageAddListing9'));
const PageAddListing10= lazy(() => import('../containers/PageAddListing1/PageAddListing10'));
const PageAddListingOTP= lazy(() => import('../containers/PageAddListing1/PageAddListingOTP'));
const PageHome2 = lazy(() => import('../containers/PageHome/PageHome2'));

const UserVenueList = lazy(() => import('../containers/DashboardPage/UserVenueList'));
const ManageVenues = lazy(() => import('../containers/DashboardPage/ManageVenues'));
const ManageReservations = lazy(() => import('containers/DashboardPage/ManageReservations'));
const ListingStatistics = lazy(() => import('containers/DashboardPage/ListingStatistics'));
const BookingSearch = lazy(() => import('containers/BookingPages/BookingSearch'));
const BookingPage = lazy(() => import('containers/BookingPages/BookingPage'));
const ManageReservationsBusiness = lazy(() => import('containers/BusinessDashboard/BusinessManageReservations'));
const ListingStatisticsBusiness = lazy(() => import('containers/BusinessDashboard/ListingStatistics'));
const BookingInvoicePage = lazy(() => import('containers/ListingDetailPage/BookingInvoicePage'));
const ManageVenuesBusiness = lazy(() => import('containers/BusinessDashboard/ManageVenues'));
const CpVenues = lazy(() => import('containers/BusinessDashboard/CpVenues'));
const CpBookings = lazy(() => import('containers/BusinessDashboard/CpBookings'));
const CpRevenue = lazy(() => import('containers/BusinessDashboard/CpRevenue'));
const CpReviews = lazy(() => import('containers/BusinessDashboard/CpReviews'));
const CpHome = lazy(() => import('containers/BusinessDashboard/CpHome'));
const CpPartialBookings = lazy(() => import('containers/BusinessDashboard/CpPartialBookings'));
const CpBzTeamContacts = lazy(() => import('containers/BusinessDashboard/CpBzTeamContacts'));
const CpPromoCodes = lazy(() => import('containers/BusinessDashboard/CpPromoCodes'));
const PageFeatured = lazy(() => import('containers/PageHome/PageFeatured'));
const PageFAQ = lazy(() => import('containers/PageFAQ/PageFAQ'));
const GreekCampusEventPage = lazy(() => import('containers/GreekCampusEventPage/GreekCampusEventPage'));
const FamilyPark = lazy(() => import('containers/Tickets/FamilyPark'));
const PageInvitees = lazy(() => import('containers/PageInvitees/PageInvitees'));
const PageSecretSauce = lazy(() => import('containers/PageSecretSauce/PageSecretSauce'));
const PagePayment = lazy(() => import('containers/PagePayment/PagePayment'));
const PageLRForm = lazy(() => import('containers/PageLRForm/PageLRForm'));
const CpLogin = lazy(() => import('containers/BusinessDashboard/CpLogin'));
const CpBlog = lazy(() => import('containers/BusinessDashboard/CpBlog'));
const BlogPageNew = lazy(() => import('containers/BlogPage/BlogPageNew'));
const BlogPageForTag = lazy(() => import('containers/BlogPage/BlogPageForTag'));
const BlogPageForAllPosts = lazy(() => import('containers/BlogPage/BlogPageForAllPosts'));
const ListingBookingOne = lazy(() => import('containers/ListingDetailPage/ListingBookingOne'));
const ListingBookingTwo = lazy(() => import('containers/ListingDetailPage/ListingBookingTwo'));
const ListingBookingThree = lazy(() => import('containers/ListingDetailPage/ListingBookingThree'));
const CpRequests = lazy(() => import('containers/BusinessDashboard/CpRequests'));
const CpChat = lazy(() => import('containers/BusinessDashboard/CpChat'));
const CpRegistrations = lazy(() => import('containers/BusinessDashboard/CpRegistrations'));
const VenueTypePage = lazy(() => import('containers/VenueTypePages/VenueTypePage'));
const CpOTP = lazy(() => import('containers/BusinessDashboard/CpOTP'));
const CpHomeAffiliate= lazy(() => import('containers/BusinessDashboard/CpHomeAffiliate'));
const CpBookingsAffiliate= lazy(() => import('containers/BusinessDashboard/CpBookingsAffiliate'));
const CpUsersManager= lazy(() => import('containers/BusinessDashboard/CpUsersManager'));
const CpBookingsAgency= lazy(() => import('containers/BusinessDashboard/CpBookingsAgency'));
const CpHomeAgency= lazy(() => import('containers/BusinessDashboard/CpHomeAgency'));
const PageAISearch = lazy(() => import('containers/PageAISearch/PageAISearch'));
const SpacesPage = lazy(() => import('../containers/ListingDetailPage/SpacesPage'));
const Register = lazy(() => import('../containers/PageSignUp/Register'));
const PageCheckout = lazy(() => import('../containers/PageCheckOut/PageCheckout'));
const PageCheckout2 = lazy(() => import('../containers/PageCheckOut/PageCheckout2'));
const PageCheckout3 = lazy(() => import('../containers/PageCheckOut/PageCheckout3'));
const PageCheckout4 = lazy(() => import('../containers/PageCheckOut/PageCheckout4'));
const PageCheckout5 = lazy(() => import('../containers/PageCheckOut/PageCheckout5'));
const AffiliatesFAQ = lazy(() => import('../containers/AffiliatesFAQ/AffiliatesFAQ'));
const CpAskHost = lazy(() => import('containers/BusinessDashboard/CpAskHost'));
const HostPro = lazy(()=> import('../containers/HostProPage/HostPro'))
const CpSubdReq = lazy(()=> import('../containers/BusinessDashboard/CpSubdReq'))
const SubHookah = lazy(()=> import('../containers/Subdomain/SubHookah'))
const SubMomento = lazy(()=> import('../containers/Subdomain/PageMomento'))

const Page13Venues = lazy(()=> import('../containers/Subdomain/PageCielo'))

const SubIRIS = lazy(()=> import('../containers/Subdomain/PageIRIS'))

// const DDBranchPage = lazy(()=> import ("containers/PageSecretSauce/DDBranchPage"));
// const CpSiteVisitsAdmins = lazy(()=> import ("containers/BusinessDashboard/CpSiteVisitsAdmins"));
// const CpSiteVisitInfo = lazy(()=> import ("containers/BusinessDashboard/CpSiteVisitDetails"));
// const PageCasa = lazy(()=> import ("containers/Subdomain/PageCasa"));
// const PageGoal = lazy(()=> import ("containers/Subdomain/PageGoal"));
// const AppDownloads = lazy(()=> import ("containers/MobileAppDownloads"));
// const CmpProfile = lazy(()=> import ("containers/ComplementaryPortal/CMPProfile"));
// const CmpPro = lazy(()=> import ("containers/HostProPage/CMPPro"));
// const CMPAntd = lazy(()=> import ("containers/ComplementaryPortal/CMPAntd"));
// const spItems = lazy(()=> import ("containers/ComplementaryPortal/spItems"));
// const spItemEdit = lazy(()=> import ("containers/ComplementaryPortal/spItemEdit"));
// const spPackageEdit = lazy(()=> import ("containers/ComplementaryPortal/SpPackagesEdit"));
// const SpPackages = lazy(()=> import ("containers/ComplementaryPortal/SpPackages"));
// const SpOffers = lazy(()=> import ("containers/ComplementaryPortal/SpOffers"));
// const SpOffersEdit = lazy(()=> import ("containers/ComplementaryPortal/SpOffersEdit"));
// const SpOfferLink = lazy(()=> import ("containers/ComplementaryPortal/OfferLink"));
// const SpLogin = lazy(()=> import ("containers/ComplementaryPortal/SpLogin"));
// const CpInvoices = lazy(()=> import ("containers/BusinessDashboard/LeadExtInvoices"));
// const CpDetailRequests = lazy(()=> import ("containers/BusinessDashboard/CpDetailRequests"));
// const PaymobPayments = lazy(()=> import ("containers/PageCheckOut/PaymobPayment"));
// const TicketsPostPurchase = lazy(()=> import ("containers/PageCheckOut/PostPurchase"));
// const PageTicket = lazy(()=> import ("containers/PageCheckOut/PageTicket"));
const TicketsTEDx2 = lazy(()=> import ("containers/Tickets/TEDxReviveEvent"));
const SpTest = lazy(()=> import ("containers/ComplementaryPortal/SpTest"));

const DDBranchPage = lazy(() =>
  import("containers/PageSecretSauce/DDBranchPage")
);
const CpSiteVisitsAdmins = lazy(() =>
  import("containers/BusinessDashboard/CpSiteVisitsAdmins")
);
const CpSiteVisitInfo = lazy(() =>
  import("containers/BusinessDashboard/CpSiteVisitDetails")
);
const PageCasa = lazy(() => import("containers/Subdomain/PageCasa"));
const PageGoal = lazy(() => import("containers/Subdomain/PageGoal"));
const AppDownloads = lazy(() => import("containers/MobileAppDownloads"));
const Cmp = lazy(() => import("containers/ComplementaryPortal/CMPTemplate"));
const CmpPackages = lazy(() =>
  import("containers/ComplementaryPortal/CMPPackages")
);
const CmpProfile = lazy(() =>
  import("containers/ComplementaryPortal/CMPProfile")
);
const CmpPro = lazy(() => import("containers/HostProPage/CMPPro"));
const CMPAntd = lazy(() => import("containers/ComplementaryPortal/CMPAntd"));
const spItems = lazy(() => import("containers/ComplementaryPortal/spItems"));
const spItemEdit = lazy(() =>
  import("containers/ComplementaryPortal/spItemEdit")
);
const spPackages = lazy(() =>
  import("containers/ComplementaryPortal/SpPackages")
);
const spPackageEdit = lazy(() =>
  import("containers/ComplementaryPortal/SpPackagesEdit")
);
const SpPackages = lazy(() =>
  import("containers/ComplementaryPortal/SpPackages")
);
const SpOffers = lazy(() => import("containers/ComplementaryPortal/SpOffers"));
const SpOffersEdit = lazy(() =>
  import("containers/ComplementaryPortal/SpOffersEdit")
);
const SpOfferLink = lazy(() =>
  import("containers/ComplementaryPortal/OfferLink")
);
const SpLogin = lazy(() => import("containers/ComplementaryPortal/SpLogin"));
const CpInvoices = lazy(() =>
  import("containers/BusinessDashboard/LeadExtInvoices")
);
const CpDetailRequests = lazy(() =>
  import("containers/BusinessDashboard/CpDetailRequests")
);
const PaymobPayments = lazy(() =>
  import("containers/PageCheckOut/PaymobPayment")
);
const TicketsPostPurchase = lazy(() =>
  import("containers/PageCheckOut/PostPurchase")
);
const PageTicket = lazy(() => import("containers/PageCheckOut/PageTicket"));
const WapSiteVisit = lazy(() => import("containers/WhatsappPages/WapSiteVisit"));
const WapAskHost = lazy(() => import("containers/WhatsappPages/WapAskHost"));
const CpBookingDetails = lazy(() => import("containers/BusinessDashboard/CpBookingDetails"));
const FlexPage = lazy(() => import("containers/FlexPages/FlexPage"));
const SpacePage = lazy(() => import("containers/FlexPages/SpacePage"));
const Walkins = lazy(() => import("containers/FlexPages/WalkinPage"));
const CpWalkins = lazy(() => import("containers/BusinessDashboard/CpWalkins"));
const GounaPOI = lazy(() => import("containers/ListingDetailPage/GounaPOI"));
const Catalogue = lazy(() => import("containers/CateringPage/Catalogue"));
const PrincessPage = lazy(() => import("containers/FlexPages/PrincessPage"));
const RoyalClubSpacePage = lazy(() => import("containers/FlexPages/RoyalClubSpacePage"));
const RoyalClubPage = lazy(() => import("containers/FlexPages/RoyalClubPage"));
const CpReferralsAffiliate = lazy(() => import("containers/BusinessDashboard/CpReferralsAffiliate"));
const CpAppUsers = lazy(() => import("containers/BusinessDashboard/CpFlutterUsers"));
const SpLeads = lazy(() => import("containers/ComplementaryPortal/SpLeads"));
const SingleOfferPage = lazy(() => import("containers/BookingPages/SingleOfferPage"));
const CpSegments  = lazy(() => import("containers/BusinessDashboard/CpSegments"));
const CpSegmentDetails  = lazy(() => import("containers/BusinessDashboard/CpSegmentDetails"));
const CpCampaigns  = lazy(() => import("containers/BusinessDashboard/CpCampaigns"));
const CampaignDetails  = lazy(() => import("containers/BusinessDashboard/CpCampaignDetails"));
const CpOffers = lazy(() => import("containers/BusinessDashboard/CpOffers"));
const HostProArabic = lazy(() => import("containers/HostProPage/HostProArabic"));
const CpPushNotifs = lazy(() => import("containers/BusinessDashboard/CpPushNotifs"));
const CpPushNotifDetails = lazy(() => import("containers/BusinessDashboard/CpPushNotifDetails"));
const TopTenListings = lazy(() => import("containers/BookingPages/TopTenListings"));
const TopStarredListings = lazy(() => import("containers/BookingPages/TopStarredListings"));
const DynamicSpacePage = lazy(() => import("containers/FlexPages/DynamicSpacePage"));
const DynamicLanding = lazy(() => import("containers/FlexPages/DynamicLanding"));
export const pages = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", exact: true, component: PageHome2 },

  { path: "/listing-stay", component: ListingStayPage },
  { path: "/GounaPOI/:id/:name", component: GounaPOI },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/GounaMap", component: GounaMap },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },
  { path: "/listing-stay-details", component: SpacesPage },
  //
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  //
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/pinned", component: PinnedSpaces },
  { path: "/account-billing", component: AccountBilling },
  //
  { path: "/blog/all", component: BlogPageForAllPosts },

  { path: "/blog", component: BlogPageNew },
  { path: "/blog-tag/:name", component: BlogPageForTag },
  { path: "/blog-draft", component: BlogPage },
  { path: "/blog-post/:id", component: BlogSingle },
  //
  { path: "/add-listing-0", component: PageAddListing0 },
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  { path: "/add-listing-confirm", component: PageAddListingOTP },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/reviews", component: PageReviews },
  { path: "/partners", component: PagePartners },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/FAQ", component: PageFAQ},
  { path: "/tickets/investigation", component: GreekCampusEventPage},
  { path: "/tickets/family-park", component: FamilyPark},
  { path: "/tickets/TEDx", component: TicketsTEDx2},
  { path: "/invitees", component: PageInvitees},
  { path: "/secretsauce", component: PageSecretSauce},
  { path: "/secretsauce/:branch", component: DDBranchPage},
  { path: "/secretsauce/:branch/:folderID", component: DDBranchPage},
  { path: "/payment/:id", component: PagePayment},
  { path: "/start", component: PageLRForm},
  { path: "/SmartSearch", component: PageAISearch},
  { path: "/catering", component: Catalogue},
  { path: "/forgot-password", component: CpForgotPassword},

  { path: "/bookinginfo", component: BookingSearch },
  { path: "/bookinginfo/:id", component: BookingSearch },
  { path: "/myrequests", component: TrackRequests },
  { path: "/myrequests/:id", component: RequestOffers },
  { path: "/offer/:offerID", component: SingleOfferPage },
  {
    path: "/myrequests/:id/matched-listings",
    component: RequestMatchedListings,
  },
  { path: "/myrequests/:id/pinned-listings", component: RequestPinnedListings },
  { path: "/booking-step-1/:id", component: ListingBookingOne },
  { path: "/booking-step-2/:id", component: ListingBookingTwo },
  { path: "/booking-step-3/:id", component: ListingBookingThree },
  { path: "/booking", component: BookingPage },
  { path: "/managereservsbusiness", component: ManageReservationsBusiness },
  { path: "/statisticsbusiness", component: ListingStatisticsBusiness },
  { path: "/managevenuesbusiness", component: ManageVenuesBusiness },
  { path: "/Featured", component: PageFeatured },
  { path: "/payments/:id", component: PaymobPayments },
  { path: "/tickets/purchased", component: TicketsPostPurchase },
  { path: "/tickets/view/:id", component: PageTicket },
  { path: "/tickets/scan/:id/:state", component: PageTicket },
  { path: "/tokenized/site-visit/:uid", component: WapSiteVisit },
  { path: "/tokenized/ask-host/:uid", component: WapAskHost },
  { path: "/invoice", component: BookingInvoicePage },
  { path: "/flex/:listingID/:name", component: SpacePage },
  { path: "/flexv2/:listingID/:name", component: RoyalClubSpacePage },
  { path: "/walkins/:userID", component: Walkins },
  { path: "/flex", component: FlexPage },
  { path: "/princess", component: PrincessPage },
  { path: "/royalclubmohammedali", component: RoyalClubPage },
  { path: "/uservenuelist", component: UserVenueList, priv: true },
  { path: "/managevenues", component: ManageVenues, priv: true },
  { path: "/managereservs", component: ManageReservations, priv: true },
  { path: "/statistics", component: ListingStatistics, priv: true },

  { path: "/cp", exact: true, component: CpLogin },
  { path: "/cp-affiliate", exact: true, component: CpLogin },
  {
    path: "/cp-affiliate/home",
    exact: true,
    component: CpHomeAffiliate,
    priv: true,
    affiliate: true,
    cpType: "affiliate",
  },
  {
    path: "/cp-affiliate/referrals",
    exact: true,
    component: CpReferralsAffiliate,
    priv: true,
    affiliate: true,
    cpType: "affiliate",
  },
  {
    path: "/cp-affiliate/bookings",
    exact: true,
    component: CpBookingsAffiliate,
    priv: true,
    affiliate: true,
    cpType: "affiliate",
  },
  {
    path: "/cp-affiliate/change-password",
    exact: true,
    component: CpChangePassword,
    priv: true,
    affiliate: true,
    cpType: "affiliate",
  },
  {
    path: "/cp-agency/home",
    exact: true,
    component: CpHomeAgency,
    priv: true,
    agency: true,
    cpType: "agency",
  },
  {
    path: "/cp-agency/change-password",
    exact: true,
    component: CpChangePassword,
    priv: true,
    agency: true,
    cpType: "agency",
  },
  {
    path: "/cp-agency/bookings",
    exact: true,
    component: CpBookingsAgency,
    priv: true,
    agency: true,
    cpType: "agency",
  },
  {
    path: "/cp-assessment/home",
    exact: true,
    component: CpAssessmentHome,
    priv: true,
    assessment: true,
    cpType: "assessment",
  },
  {
    path: "/cp-assessment/prospects",
    exact: true,
    component: CpAssessmentProspects,
    priv: true,
    assessment: true,
    cpType: "assessment",
  },
  {
    path: "/cp-assessment/individuals",
    exact: true,
    component: CpAssessmentProspectsTwo,
    priv: true,
    assessment: true,
    cpType: "assessment",
  },

  { path: "/cp/otp", component: CpOTP, priv: false },
  { path: "/cp/venues", component: CpVenues, priv: true, cpType: "core" },
  { path: "/cp/home", component: CpHome, priv: true, cpType: "core" },
  { path: "/cp/bookings", component: CpBookings, priv: true, cpType: "core" },
  { path: "/cp/bookings/:id", component: CpBookingDetails, priv: true, cpType: "core" },
  { path: "/cp/revenue", component: CpRevenue, priv: true, cpType: "core" },
  { path: "/cp/walkins", component: CpWalkins, priv: true, cpType: "core" },
  { path: "/cp/segments", component: CpSegments, priv: true, cpType: "core" },
  { path: "/cp/segments/:segmentID", component: CpSegmentDetails, priv: true, cpType: "core" },
  { path: "/cp/campaigns", component: CpCampaigns, priv: true, cpType: "core" },
  { path: "/cp/campaigns/:campaignID", component: CampaignDetails, priv: true, cpType: "core" },
  { path: "/cp/offers", component: CpOffers, priv: true, cpType: "core" },
  { path: "/cp/push-notifs", component: CpPushNotifs, priv: true, cpType: "core" },
  { path: "/cp/push-notifs/user/:id", component: CpPushNotifDetails, priv: true, cpType: "core" },

  { path: "/cp/reviews", component: CpReviews, priv: true, cpType: "core" },
  { path: "/cp/links", component: CpLinks, priv: true, cpType: "core" },
  {
    path: "/cp/change-password",
    component: CpChangePassword,
    priv: true,
    cpType: "core",
  },
  {
    path: "/cp/partial-bookings",
    component: CpPartialBookings,
    priv: true,
    cpType: "core",
  },
  { path: "/cp/ask-host", component: CpAskHost, priv: true, cpType: "core" },
  {
    path: "/cp/bzTeamContacts",
    component: CpBzTeamContacts,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/promoCodes",
    component: CpPromoCodes,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/registrations",
    component: CpRegistrations,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/users-manager",
    component: CpUsersManager,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/blog",
    component: CpBlog,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/chat",
    component: CpChat,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/qualified-leads",
    component: CpQualifiedLeads,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/qualified-leads/quality-control",
    component: CpQLsQC,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/qualified-leads-owners/quality-control",
    component: CpQLsQC,
    priv: true,
    cpType: "core",
  },
  {
    path: "/cp/qualified-leads-owners/invoices/:id",
    component: CpInvoices,
    priv: true,
    cpType: "core",
  },
  {
    path: "/cp/qualified-leads-owners",
    component: CpQualifiedLeadsOwners,
    priv: true,
    cpType: "core",
  },
  {
    path: "/cp/create-offer/:leadID/:listingID",
    component: CpCreateOffer,
    priv: true,
    cpType: "core",
  },
  {
    path: "/cp/create-offer/:leadID/:listingID/:preOfferID",
    component: CpCreateOffer,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/requests",
    exact: true,
    component: CpRequests,
    priv: true,
    superAdmin: true,
    cpType: "core",
  },
  {
    path: "/cp/subdomainreq",
    exact: true,
    component: CpSubdReq,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/site-visits",
    exact: true,
    component: CpSiteVisitsAdmins,
    priv: true,
    cpType: "core",
  },
  {
    path: "/cp/site-visits/:id",
    exact: true,
    component: CpSiteVisitInfo,
    priv: true,
    admin: true,
    cpType: "core",
  },
  // { path: "/cp/prospects",exact:true, component: CpProspects, priv:true,admin:true, cpType:"core"},
  {
    path: "/cp/space-prospects",
    exact: true,
    component: CpSpaceProspectsAdmins,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/b2b2c-prospects",
    exact: true,
    component: CpB2b2cProspectsAdmins,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/detail-requests",
    exact: true,
    component: CpDetailRequests,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/link-tracker",
    exact: true,
    component: CpLinkTracker,
    priv: true,
    admin: true,
    cpType: "core",
  },
  {
    path: "/cp/appusers",
    exact: true,
    component: CpAppUsers,
    priv: true,
    admin: true,
    cpType: "core",
  },

  {
    path: "/cp/reports",
    exact: true,
    component: CpReports,
    priv: true,
    superAdmin: true,
    cpType: "core",
  },

  { path: "/:umbrella/:type/listing-stay", component: ListingStayPage },

  { path: "/spaces/:urlVenueType", component: VenueTypePage },
  { path: "/partners/register", exact: true, component: Register },
  { path: "/partner/:affiliate", exact: true, component: PageHome },
  { path: "/entity", exact: true, component: PageEntity },
  { path: "/restaurant", exact: true, component: PageRestaurant },
  { path: "/skas", exact: true, component: SkasRestaurant },
  { path: "/checkout/personal-info/:id", exact: true, component: PageCheckout },
  { path: "/checkout/date/:id", exact: true, component: PageCheckout2 },
  {
    path: "/checkout/packages-addons/:id",
    exact: true,
    component: PageCheckout3,
  },
  {
    path: "/checkout/payment-options/:id",
    exact: true,
    component: PageCheckout4,
  },
  { path: "/checkout/confirmation/:id", exact: true, component: PageCheckout5 },
  { path: "/AffiliatesFAQ", exact: true, component: AffiliatesFAQ },
  { path: "/VillaSafi", exact: true, component: SubPage2 },
  { path: "/hostdemo", exact: true, component: HostPro },
  { path: "/hostdemoAr", exact: true, component: HostProArabic },
  { path: "/Hookah", exact: true, component: SubHookah },

  { path: "/Cielo", exact: true, component: Page13Venues },
  { path: "/Cilio", exact: true, component: PageCilio },

  { path: "/IRIS", exact: true, component: SubIRIS },

  { path: "/Glamour", exact: true, component: SubMomento },
  { path: "/CasaNovelle", exact: true, component: PageCasa },
  { path: "/GoalCafe", exact: true, component: PageGoal },
  { path: "/app", exact: true, component: AppDownloads },
  { path: "/sp/packages", exact: true, component: SpPackages },
  { path: "/sp/profile", exact: true, component: CmpProfile },
  { path: "/sp", exact: true, component: CmpPro },
  { path: "/sp/login", exact: true, component: SpLogin },
  { path: "/sp/antd", exact: true, component: CMPAntd },
  { path: "/sp/items", exact: true, component: spItems },
  { path: "/sp/items/:id", exact: true, component: spItemEdit },
  { path: "/sp/packages/:id", exact: true, component: spPackageEdit },
  { path: "/sp/offers", exact: true, component: SpOffers },
  { path: "/sp/offers/:id", exact: true, component: SpOffersEdit },
  { path: "/sp/leads", exact: true, component: SpLeads },

  { path: "/sp/test", exact: true, component: SpTest },
  { path: "/preview/offer/:id", exact: true, component: SpOfferLink },
  { path: "/top-10-spaces", exact: true, component: TopTenListings },
  { path: "/top-social-spaces", exact: true, component: TopStarredListings },
  { path: "/Host-pro/:listingID/:name", component: DynamicSpacePage },
  { path: "/landing/:userID/:name", component: DynamicLanding },

];

const check = (param, type, superAdmin) => {
  let rank = parseJwt(localStorage.getItem("CpUser14")).user.rank;
  if (rank === "Admin" && !superAdmin) {
    return true;
  } else {
    if (rank === "Affiliate" && param === rank && type === "affiliate") {
      return true;
    }
    if (rank === "Agency" && param === rank && type === "agency") {
      return true;
    }
    if (rank === "Owner" && param === rank && type === "core") {
      return true;
    }
    if (rank === "Blogger" && param === rank && type === "core") {
      return true;
    }
    if (rank === "Assessment" && param === rank && type === "assessment") {
      return true;
    } else {
      return false;
    }
  }
};

const Routes = () => {
  const authentication = useAuthStore((state) => state.checkAuth);
  const spAuthentication = useAuthStore((state) => state.checkSpAuth);
  const setUser = useAuthStore((state) => state.setUser);
  const setSpUser = useAuthStore((state) => state.setSpUser);
  const setGffUser = useAuthStore((state)=>state.setGffUser);
  const setFavs = useAppStore((state) => state.setFavs);
  const showNavBar = useCPStore((state) => state.showNavBar);
  const gffAuthentication = useAuthStore((state)=> state.checkGffAuth)
  const windowSize = useWindowSize();
  console.log("rerendering routes");

  if (authentication()) {
    const accessToken = localStorage.getItem("CpUser14");
    const user = parseJwt(accessToken);
    setUser(user.user);
  }
  if (spAuthentication()) {
    const accessToken = localStorage.getItem("SpUser6");
    const user = parseJwt(accessToken);
    setSpUser(user.user);
  }
  if(gffAuthentication()){
    const user = JSON.parse(localStorage.getItem("GffUser"));
    setGffUser(user);
  }

  useEffect(async () => {
    const response = await axios.post(`${url}/listing/getCacheMetaData`);
    localStorage.setItem("cacheMetaData", JSON.stringify(response.data));
    console.log(response.data);

    let lastPercentCacheDate = localStorage.getItem("localPercentDate");
    if (lastPercentCacheDate) {
      let dateObj = moment(lastPercentCacheDate);
      let currentDate = moment();
      if (dateObj.add(6, "hours").isBefore(currentDate)) {
        for (let i = 0; i < 1000; i++) {
          let key = "vID" + i;
          let randomPercent = Math.floor(Math.random() * 3) + 1;
          localStorage.setItem(key, randomPercent);
        }
      }
    } else {
      localStorage.setItem("localPercentDate", moment().toString());
    }
  }, []);

  useEffect(() => {
    let phoneNumber = localStorage.getItem("phoneNumber");
    if (phoneNumber) {
      axios
        .post(`${url}/favourites/get`, { phoneNumber: phoneNumber })
        .then((res) => {
          setFavs(res.data);
        });
    }
  }, []);

  return (
    <BrowserRouter basename="/Venu">
      <ScrollToTop />
      {showNavBar && <Header3 />}
      {/* <NewNavBar/> */}

      <Switch>
        <Suspense fallback={<DelayedFallback />}>
          {pages.map(
            ({
              component,
              path,
              exact,
              priv,
              admin,
              superAdmin,
              affiliate,
              agency,
              cpType,
              assessment,
            }) => {
              if (!priv) {
                return (
                  <Route
                    key={path}
                    component={component}
                    exact={true}
                    path={path}
                  />
                );
              } else if (priv) {
                if (authentication()) {
                  if (
                    parseJwt(localStorage.getItem("CpUser14")).user.rank ===
                      "Blogger" &&
                    path === "/cp/blog"
                  ) {
                    console.log("got to blogger 1");
                    return (
                      <Route
                        key={path}
                        component={component}
                        exact={true}
                        path={path}
                      />
                    );
                  }
                  if (
                    parseJwt(localStorage.getItem("CpUser14")).user.rank ===
                      "Blogger" &&
                    path !== "/cp/blog"
                  ) {
                    console.log("got to blogger 2");
                    return (
                      <Route
                        exact={true}
                        component={CpLogin}
                        path={path}
                      ></Route>
                    );
                  } else if (admin) {
                    if (check("Admin")) {
                      return (
                        <Route
                          key={path}
                          component={component}
                          exact={true}
                          path={path}
                        />
                      );
                    } else {
                      return <Route path={"/cp"}></Route>;
                    }
                  } else {
                    //doesnt require admin

                    if (affiliate) {
                      if (check("Affiliate", cpType)) {
                        return (
                          <Route
                            key={path}
                            component={component}
                            exact={true}
                            path={path}
                          />
                        );
                      } else {
                        return (
                          <Route
                            exact={true}
                            component={CpLogin}
                            path={path}
                          ></Route>
                        );
                      }
                    }
                    if (agency) {
                      if (check("Agency", cpType)) {
                        return (
                          <Route
                            key={path}
                            component={component}
                            exact={true}
                            path={path}
                          />
                        );
                      } else {
                        return (
                          <Route
                            exact={true}
                            component={CpLogin}
                            path={path}
                          ></Route>
                        );
                      }
                    }
                    if (assessment) {
                      if (check("Assessment", cpType)) {
                        return (
                          <Route
                            key={path}
                            component={component}
                            exact={true}
                            path={path}
                          />
                        );
                      } else {
                        return (
                          <Route
                            exact={true}
                            component={CpLogin}
                            path={path}
                          ></Route>
                        );
                      }
                    }

                    if (superAdmin) {
                      if (
                        parseJwt(localStorage.getItem("CpUser14")).user
                          .superAdmin ||
                        check("Owner", cpType, superAdmin)
                      ) {
                        return (
                          <Route
                            key={path}
                            component={component}
                            exact={true}
                            path={path}
                          />
                        );
                      } else {
                        return (
                          <Route
                            key={path}
                            component={BookingInvoicePage}
                            exact={true}
                            path={"/FAQ"}
                          />
                        );
                      }
                    } else {
                      if (check("Owner", cpType)) {
                        return (
                          <Route
                            key={path}
                            component={component}
                            exact={true}
                            path={path}
                          />
                        );
                      } else {
                        return (
                          <Route
                            key={path}
                            component={CpLogin}
                            exact={true}
                            path={path}
                          />
                        );
                      }
                    }
                  }
                } else {
                  console.log("defaulted to not authenticated");
                  return (
                    <Route exact={true} component={CpLogin} path={path}></Route>
                  );
                  //   key={path}
                  //   component={CpLogin}
                  //   exact={false}
                  //   path={'/cp'}
                  // />
                }
              }
            }
          )}
        </Suspense>
        <Route component={Page404} />
      </Switch>

      {windowSize.width < 1024 && showNavBar && <FooterNav />}
      {windowSize.width >= 1024 && showNavBar && <Footer />}
    </BrowserRouter>
  );
};

// function PrivateRoute ({component: Component, authed, ...rest}) {
//   return (
//     <Route
//       {...rest}
//       render={(props) => authed === true
//         ? <Component {...props} />
//         : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
//     />
//   )
// }
export default Routes;
