
  import {
    BellIcon,
    BriefcaseIcon,
    ChatIcon,
    CogIcon,
    DocumentSearchIcon,
    FolderIcon,
    HomeIcon,
    MenuAlt2Icon,
    QuestionMarkCircleIcon,
    CurrencyDollarIcon,
    UsersIcon,
    XIcon,
    CollectionIcon,
    ChatAlt2Icon,
    TagIcon,
    EyeIcon,
    AnnotationIcon,BookOpenIcon, UserCircleIcon, UserGroupIcon, DocumentTextIcon, LightBulbIcon, MailIcon, UserAddIcon, DeviceMobileIcon, GlobeIcon, PuzzleIcon, TrendingUpIcon
  } from '@heroicons/react/outline'

  import venuLogo from "images/VLogo.PNG"
  import { useSnackbar } from 'snackfy';
  import v53 from "images/v53.jpg"
import { ChatAltIcon, DocumentIcon, LinkIcon, LocationMarkerIcon, PaperAirplaneIcon } from '@heroicons/react/solid';
import moment from 'moment';
import useBookingStore from './bookingStore';
import { PencilAltIcon } from '@heroicons/react/solid';
import useAppStore from './appStore';


const public_data_offers= [
    {
      id: "1",
      href: "/listing-stay-detail?id=44",
      name: "Enjoy the Nile shade and view",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "	https://drive.google.com/uc?export=view&id=1Ju0EN6TsVlQi6Wg09LlSZwjcSQP0OXVp",
    },
    {
      id: "222",
      href: "/listing-stay-detail?id=53",
      name: "Summer vibes await you",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        v53,
    },
    {
      id: "3",
      href: "/listing-stay-detail?id=47",
      name: "Arabian breezy tents",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "	https://drive.google.com/uc?export=view&id=1HYBWqHp6Id6xPBAC8dUhVVEOYxtN7F-p",
    },
    {
      id: "4",
      href: "/listing-stay-detail?id=48",
      name: "Cosy Indoors ",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://drive.google.com/uc?export=view&id=1MmCbQEMS7hxHwG8UETEdQCmxbTFfd-Cc",
    },
  ];




  const horizontalTabs = [
    { name: 'General', href: '#', current: true },
    { name: 'Password', href: '#', current: false },
    { name: 'Notifications', href: '#', current: false },
    { name: 'Plan', href: '#', current: false },
    { name: 'Billing', href: '#', current: false },
    { name: 'Team ', href: '#', current: false },
  ]
  let limitedUsers = [404,408,415, 447, 448, 449, 450 , 454, 455, 457 , 456,489, 492, 481, 494, 495,496,497,498, 499, 506, 507, 527]
  let limitedAdmins = [530]
  let limitedSubaccounts = [515, 516];
  let hatchetReadOnly = [519];
  let hatchetBookingsOnly = [519,520]
  let hatchetDoor = [521];
  let RcmaSubaccounts = [526];



  const verticalTabs = [
    { name: 'Home', href: '/cp/home', icon: HomeIcon,  },
    { name: 'App Users', href: '/cp/appusers', icon: DeviceMobileIcon, admin:true, superAdmin : true },
    { name: 'Qualified Leads', href: '/cp/qualified-leads-owners', icon: CollectionIcon, userOnly:true},
    { name: 'Segments & Audience', href: '/cp/segments', icon: GlobeIcon, superAdmin:true,admin:true,},
    { name: 'Campaigns', href: '/cp/campaigns', icon: MailIcon, superAdmin:true,admin:true,},
    { name: 'Users in Automation', href: '/cp/push-notifs', icon: TrendingUpIcon, superAdmin:true,admin:true,},
    { name: 'Offers', href: '/cp/offers', icon: TagIcon,admin:true,},
    { name: 'Space Prospects', href: '/cp/space-prospects',admin : true , icon: LightBulbIcon,},
    { name: 'B2B2C Prospects', href: '/cp/b2b2c-prospects',admin : true , icon: UsersIcon,},
    // { name: 'Prospects', href: '/cp/prospects',admin : true , icon: LightBulbIcon,},
    { name: 'Qualified Leads Admins', href: '/cp/qualified-leads',admin : true , icon: CollectionIcon,  },
    { name: 'Link Tracker', href: '/cp/link-tracker',admin : true , icon: LinkIcon,  },
    { name: 'Walk-ins & Customers', href: '/cp/walkins', icon: UserAddIcon,  },


    { name: 'Site Visits', href: '/cp/site-visits' , icon: LocationMarkerIcon,  },
    { name: 'Bookings', href: '/cp/bookings', icon: DocumentSearchIcon, locked:true    },
    { name: 'Revenue', href: '/cp/revenue', icon: CurrencyDollarIcon, locked:true  },
    { name: 'Registrations', href: '/cp/registrations', icon: UserCircleIcon,admin:true, superAdmin : true  },
    { name: 'Users Manager', href: '/cp/users-manager', icon: UserGroupIcon,admin:true, superAdmin : true  },
    { name: 'Ask a host', href: '/cp/ask-host', icon: QuestionMarkCircleIcon   },
    // { name: 'Detail Requests', href: '/cp/detail-requests', icon: MailIcon ,admin:true  },

    { name: 'Partial Bookings', href: '/cp/partial-bookings', icon: FolderIcon, locked:true, admin:true, superAdmin : true },
    // { name: 'Links', href: '/cp/links', icon: LinkIcon , admin : true, superAdmin : true},
    { name: 'Requests', href: '/cp/requests', icon:PencilAltIcon, locked:false, superAdmin:true },
    { name: 'Sub. Requests', href: '/cp/subdomainreq', icon:PaperAirplaneIcon, locked:false, admin:true, superAdmin : true },
    { name: 'Messages', href: '/cp/bzTeamContacts', icon: ChatAlt2Icon,admin:true, superAdmin : true  },
    { name: 'Promo Codes', href: '/cp/promoCodes', icon: TagIcon, admin:true, superAdmin : true },

    { name: 'Venues', href: '/cp/venues', icon: CollectionIcon,  },
    { name: 'Reports', href: '/cp/reports', icon: DocumentTextIcon, admin:true ,superAdmin : true },
    { name: 'Blog', href: '/cp/blog', icon: BookOpenIcon,admin:true, blogger : true, superAdmin : true  },
    // { name: 'Chat', href: '/cp/chat', icon: ChatAltIcon,admin:true, superAdmin : true  },

  ]
  const affiliateVerticalTabs = [
    { name: 'Home', href: '/cp-affiliate/home', icon: HomeIcon,  },
    { name: 'Bookings', href: '/cp-affiliate/bookings', icon: DocumentSearchIcon,   },
    { name: 'Referrals', href: '/cp-affiliate/referrals', icon: UsersIcon,   },
    // { name: 'Revenue', href: '/cp-affiliate/revenue', icon: CurrencyDollarIcon, },
    // { name: 'Promo Codes', href: '/cp-affiliate/promoCodes', icon: TagIcon,},
  ]
  const marketingVerticalTabs = [
    { name: 'Home', href: '/cp-agency/home', icon: HomeIcon,  },
    { name: 'Bookings', href: '/cp-agency/bookings', icon: DocumentSearchIcon,   },
    // { name: 'Savings', href: '/cp-agency/savings', icon: TagIcon, },
   
  ]
  const assessmentVerticalTabs = [
    { name: 'Home', href: '/cp-assessment/home', icon: HomeIcon,  },
    { name: 'Spaces', href: '/cp-assessment/prospects', icon: LightBulbIcon,   },
    { name: 'Individuals', href: '/cp-assessment/individuals', icon: UsersIcon,   },
    // { name: 'Savings', href: '/cp-agency/savings', icon: TagIcon, },
   
  ]
  const discountedListings = [2,27,4,1,41,55,114,91,57,3,349,158,51,44,350,86,31,,327,159,39,53,108,151,29,16,217,
    90,372,85,43,9,389,387,47,20,113,80,40,386,45,144,445,50,477,46,374,365,7,56,444,378,428,193,197,
  520,192,353,571,260,536,356,198,195,529,194,410,355,565,354,196,549,564,582,519,572,518,492,493,614,609,167, 96,
  98,
  99,
  95,
  101,
  97,
  100,
  102,
  106,
  127,
  133,
  141,
  130,
  134,
  137,
  140,
  123,
  136,
  139,
  124,
  125,
  128,
  160,
  162,
  142,
  226,
  230,
  202,
  214,
  229,
  311,
  312,
  365,
  444,
  473,
  522,
  528,
  529,
  530,
  538,
  570,
  582,
  626,
  637,
  638,
  639,
  640,
  644,
  645,
  656,
  658,
  660,
  661,
  662,
  663,
  664,
  665,
  666,
  667,
  668,
  669,
  670,
  671,
  672,
  673,
  674,
  675,
  676,
  677,
  678,
  679,
  680,
  681
]

  const user = {
    name: "Venu",
    imageUrl:venuLogo
      
  };

  const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }


  let snackbarOptions = {
    message : "Listing Updated Successfully",
    options : {
      customStyle :{ info : {'background-color' : "#957bb8", 'z-index': 200},},
       
        variant : 'info'
    },


    
}    

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
charactersLength));
 }
 return result;
}


const includesObjectByID=(array,object)=>{
  const newArr = array.filter((item)=>{
    return item.id == object.id || item.name == object.name
  })


  if(newArr.length > 0){
    return true
  }
  else
  return false;
}


function isJsonObject(strData) {
  try {
      JSON.parse(strData);
  } catch (e) {
      return false;
  }
  return true;
}


const REACT_APP_AUTH_KEY="396874d2bf18366d963b7cb2d1701ad1580d8b30"
const REACT_APP_ID="221579d4337d18fa";
const REACT_APP_AGENT_ID="owner"
const REACT_APP_REGION="eu"
const REACT_APP_W1="16c7a1e1-b18d-4010-90a3-3fef4b750a3f"
const REACT_APP_W2="6695c328-6a30-4c35-bcf7-1d9ddc2cb895"

const animationSteps = [{
  style: {
    opacity: 0,
  },
  duration: 100,
}, {
  style: {
    opacity: 1,
    transform: 'translate(0, 0)',
  },
  duration: 1000,
},];


function generate(n) {
  var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   
  
  if ( n > max ) {
          return generate(max) + generate(n - max);
  }
  
  max        = Math.pow(10, n+add);
  var min    = max/10; // Math.pow(10, n) basically
  var number = Math.floor( Math.random() * (max - min + 1) ) + min;
  
  return ("" + number).substring(add); 
}

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const firstloadingSteps = ["Processing your input" ,
 "Fetching Available Spaces", "Filtering By Location" , "Checking Active Locations" , "Prioritizing Budget",
"Calculating Accuracy" , "Arranging Results" , "Finalizing Results"]


function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const EventTypeSwitcher = (typeID)=>{
   let allTypes = useAppStore(state=>state.allTypes);
   for (let i = 0; i < allTypes.length; i++) {
    const type = allTypes[i];
    if(type.id == typeID){
      return {
        name : type.title,
        icon : type.icon
      }
    }
    
   }
}

function getOperatingSystem(window) {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
  if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

  return operatingSystem;
}

function getBrowser(window) {
  let currentBrowser = 'Not known';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
  else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
  else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
  else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
  else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
  else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
  else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
  else { console.log('Others'); }

  return currentBrowser;
}
function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


  export{
      public_data_offers,
      horizontalTabs,
      verticalTabs,
      user,
      snackbarOptions,
      toastOptions,
      parseJwt,
      makeid,
      includesObjectByID,
      isJsonObject,
      REACT_APP_AGENT_ID,
      REACT_APP_AUTH_KEY,
      REACT_APP_ID,
      REACT_APP_REGION,
      REACT_APP_W1,
      REACT_APP_W2,
      animationSteps,
      randomIntFromInterval,
      generate,
      firstloadingSteps,
      shuffle,
      affiliateVerticalTabs,
      marketingVerticalTabs,
      EventTypeSwitcher,
      getOperatingSystem,
      getBrowser,
      iOS,
      limitedUsers,
      assessmentVerticalTabs,
      discountedListings,
      classNames,
      limitedAdmins,
      limitedSubaccounts,
      hatchetReadOnly,
      hatchetBookingsOnly,
      hatchetDoor,
      RcmaSubaccounts
      
  }