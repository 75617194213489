import create from 'zustand'

const useAuthStore = create(set => ({
    user : null,
    spUser : null,
    gffUser : null,

    setUser: (user) => set(state => ({user : user})),
    setSpUser: (user) => set(state => ({spUser : user})),
    setGffUser : (user)=>set(state=>({gffUser : user})),
    checkAuth : ()=>{
        if(localStorage.getItem('CpUser14') !=null){
            return true
        }
        else
        return false
    },
    checkSpAuth : ()=>{
        if(localStorage.getItem('SpUser6') !=null){
            return true
        }
        else
        return false
    },
    checkGffAuth : ()=>{
        if(localStorage.getItem('GffUser') !=null){
            return true
        }
        else
        return false
    },
    tempLoginInfo : null,
    settempLoginInfo : (info)=> set({tempLoginInfo:info}),
    realOTP : "",
    setrealOTP : (realOTP) => set({realOTP:realOTP}),
    accessToken : "",
    setaccessToken : (token)=>set({accessToken:token}),
    spAccessToken : "",
    setSpAccessToken : (token)=>set({spAccessToken:token})




}))

export default useAuthStore